<template>
    <main class="swcluster-main" id="swcluster-datasandbox-athena">
        <!-- page-header -->
        <div class="page-header header-divider">
            <div class="header-component">
                <CommonBreadcrumb />
                <h2 class="page-title">&nbsp;</h2>
            </div>
        </div>
        <!-- page-body -->
        <div class="page-body page-component">
            <!-- page-contents -->
            <div class="page-contents">
                <!-- content-section:결과 -->
                <article class="content-section section-metadata">
                    <header class="section-header">
                        <h3 class="title title-sm">ID {{ id }}</h3>
                    </header>
                    <div class="metadata-container">
                        <div class="metadata-item">
                            <span class="metadata-title">데이터 원본</span>
                            <p class="metadata-content">{{ meta.catalog ? meta.catalog : '-' }}</p>
                        </div>
                        <div class="metadata-item">
                            <span class="metadata-title">데이터 베이스</span>
                            <p class="metadata-content">{{ meta.db ? meta.db : '-' }}</p>
                        </div>
                        <div class="metadata-item">
                            <span class="metadata-title">쿼리</span>
                            <p class="metadata-content">{{ meta.query }}</p>
                        </div>
                        <!--                        <div class="metadata-item">-->
                        <!--                            <span class="metadata-title">상태</span>-->
                        <!--                            <p class="metadata-content">{{ meta.status === 'SUCCEEDED' ? '성공' : '-' }}</p>-->
                        <!--                        </div>-->
                    </div>
                </article>
                <!-- content-section:결과 -->
                <article class="content-section section-divider section-result">
                    <header class="section-header d-flex">
                        <h3 class="title title-sm">
                            결과 <span class="title-sub">{{ paging.totalCount }}</span>
                        </h3>
                        <!--                        <div class="header-side side-icon-actions">-->
                        <!--                            <button class="btn-action"><i class="icon-copy"></i><span class="text">복사</span></button>-->
                        <!--                            <a href="#" download="" class="btn-action"><i class="icon-download"></i><span class="text">다운로드</span></a>-->
                        <!--                        </div>-->
                    </header>
                    <div v-if="isReady" class="table-container">
                        <div class="table table-outline swcc-scroll-table">
                            <table>
                                <colgroup>
                                    <col style="width: 66px" />
                                </colgroup>
                                <thead>
                                    <tr>
                                        <th>
                                            <div class="table-cell"><span class="cell-title">#</span></div>
                                        </th>
                                        <th v-for="(header, index) in table.headers" :key="index">
                                            <div class="table-cell">
                                                <span class="cell-title">{{ header }}</span>
                                            </div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(body, index) in renderBodies" :key="index">
                                        <td>
                                            <div class="table-cell">
                                                <span class="cell-text">{{ getPageNo(paging, index) }}</span>
                                            </div>
                                        </td>
                                        <td v-for="(td, idx) in body" :key="`${index}-${idx}`">
                                            <div class="table-cell">
                                                <span class="cell-text">{{ td }}</span>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <!-- pagination -->
                        <CommonPagination :paging="paging" :page-func="pageFunc" />
                    </div>
                </article>
            </div>
            <!-- //page-contents -->
        </div>
        <!-- //page-body -->
        <!-- page-bottom -->
        <div class="page-bottom bottom-fixed">
            <div>
                <div class="bottom-search-container bottom-component">
                    <div class="bottom-search">
                        <div class="search-column column-form">
                            <input type="search" placeholder="행을 검색하세요." />
                        </div>
                        <div class="search-column column-button">
                            <button class="btn-icon"><i class="icon-search"></i></button>
                        </div>
                    </div>
                    <div class="bottom-side">
                        <button class="btn-action"><span class="text">목록</span></button>
                    </div>
                </div>
            </div>
        </div>
        <!-- //page-bottom -->
    </main>
</template>

<script>
import {useRoute, useRouter} from 'vue-router';
import {computed, reactive, ref, watch} from 'vue';
import ApiService from '@/assets/js/api.service';
import CommonBreadcrumb from '@/components/common/layout/CommonBreadcrumb';
import {getItem, lengthCheck, setParams, getPageNo, paginate} from '@/assets/js/common.utils';
import CommonPagination from '@/components/common/CommonPagination';
import {getValueByQuery} from '@/assets/js/route.utils';
import {goAws} from '@/assets/js/modules/aws/module';

export default {
    name: 'AthenaView',
    components: {CommonPagination, CommonBreadcrumb},
    setup: function () {
        const route = useRoute();
        const router = useRouter();
        const id = computed(() => route.params.id);

        const isReady = ref(false);

        const paging = reactive({
            pageNo: getValueByQuery(route.query, 'pageNo', true, 1),
            pageSize: 10,
            totalCount: computed(() => table.bodies.length),
        });

        const meta = reactive({
            db: '',
            catalog: '',
            query: '',
            status: '',
        });

        const table = reactive({
            headers: [],
            bodies: [],
        });

        const renderBodies = computed(() => paginate(table.bodies, paging.pageNo, paging.pageSize));

        const pageFunc = pageNo => {
            router.push({query: {pageNo: pageNo}});
        };

        const getAthenaLog = () => {
            ApiService.get('/v1/aws/athena/logs', id.value).then(res => {
                if (lengthCheck(res)) {
                    const item = getItem(res);
                    setParams(meta, item);
                    if (item.rows && item.rows.length > 0) {
                        table.headers = item.rows.shift();
                        table.bodies = item.rows;
                        isReady.value = true;
                    }
                }
            });
        };

        watch(
            () => route.query,
            () => {
                if (route.name === 'AthenaView') {
                    paging.pageNo = getValueByQuery(route.query, 'pageNo', true, 1);
                }
            },
        );

        getAthenaLog();

        return {
            id,
            isReady,
            paging,
            meta,
            table,
            renderBodies,
            goAws,
            getPageNo,
            pageFunc,
        };
    },
};
</script>
